<template>
  <div class="file-input" @click="triggerFileInput" :class="{'pointer-none': disabled}">
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      :accept="accept"
      multiple
      style="display: none;"
    />
    <img src="@/assets/file-input.svg" alt="">
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png, .xlsx, .xls, .pdf, .doc, .docx, .pptx, .ppsx, .odp'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleFileChange(event) {
      const files = event.target.files;
      this.$emit('change', files);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    }
  }
};
</script>

<style scoped>
.file-input {
  cursor: pointer;
}
.custom-file-input {
  width: 200px;
  height: 150px;
  border-radius: 15px;
  border: 2px dashed #2EB85C;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.custom-file-input::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px dashed #2EB85C;
  border-radius: 15px;
  pointer-events: none;
}

.file-input-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.attachment-icon {
  font-size: 26px;
  transform: rotate(45deg);
  color: #2EB85C;
}

.label {
  margin-top: 8px;
  font-size: 14px;
  color: #2EB85C;
}

.pointer-none {
  pointer-events: none;
}
</style>

<template>
	<div class="notifications-table">
		<v-data-table
			class="overflow-x elevation-1 infinite-list"
			:headers="base_role == 'dispatcher' ? headersDispatcher : headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			height="100%"
			item-key="id"
			hide-default-footer
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<v-btn
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Создать</span>
							</v-btn>
							<!-- <div class="d-flex align-center mr-3">
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									:date.sync="filter.created_at_first_search"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									:date.sync="filter.created_at_second_search"
									@change="updateQuery"
								/>
								<v-btn class="ml-3" icon>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</div> -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<!-- <v-btn
								class="mr-5"
								color="primary"
								small
								depressed
								:loading="isExportingExcel"
								@click="exportExcel"
								v-if="base_role !== 'dispatcher'"
							>
								<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
								<span class="body-2 font-weight-light text-capitalize">
									Экспорт
								</span>
							</v-btn> -->
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<td>
						<!-- <v-text-field
							v-model="filter.pretty_id"
							placeholder="ID"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field> -->
					</td>
					<td v-if="base_role === 'dispatcher'">
						<v-autocomplete
							v-model="filter.company_ids"
							class="border-all"
							placeholder="УК"
							solo
							dense
							flat
							multiple
							hide-details
							clearable
							item-text="name"
							item-value="_id"
							:items="kskListConfirm"
							:filter="addressFilter"
							@change="updateQuery"
						>
						</v-autocomplete>
					</td>
					<td>
						<v-text-field
							v-model="filter.name"
							placeholder="Тема"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<!-- <v-text-field
							v-model="filter.description"
							placeholder="Описание"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field> -->
					</td>
					<td>
						<combobox
							v-model="filter.house_ids"
							placeholder="Адрес"
							:items="houses"
							@change="updateQuery"
							@select-all="updateFilter('house_ids', $event)"
						/>
						<!-- <v-combobox
							v-model="filter.house_ids"
							placeholder="Адрес"
							class="text-caption border-all"
							solo
							multiple
							clearable
							item-value="id"
							item-text="apartment_address"
							:return-object="false"
							:items="houses"
							:filter="addressFilter"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && item.length >= 1">
									{{ currentHouses(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.house_ids.length - 1 }})
								</span>
							</template>
						</v-combobox> -->
						<!-- <v-autocomplete
							v-model="filter.house_ids"
							class="border-all"
							solo
							dense
							flat
							hide-details
							item-value="id"
							item-text="apartment_address"
							:items="houses"
							multiple
							:filter="addressFilter"
							chips
							small-chips
							@change="updateQuery"
						> -->
							<!-- <template v-slot:prepend-item>
								<v-list-item @click="selectAllHouses">
									<v-list-item-action>
										<v-icon color="primary">mdi-check-all</v-icon>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>Выбрать все</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template> -->
						<!-- </v-autocomplete> -->
					</td>
					<td>
						<combobox
							v-if="base_role !== 'dispatcher'"
							v-model="filter.user_ids"
							placeholder="От кого"
							type="employee"
							:items="employee"
							@change="updateQuery"
							@select-all="updateFilter('user_ids', $event)"
						/>
						<!-- <v-select
							v-model="filter.created_by_user"
							placeholder="От кого"
							class="border-all"
							solo
							dense
							flat
							hide-details
							item-value="id"
							:item-text="
								item => `${item.surname} ${item.name} ${item.patronymic || ''}`
							"
							:items="employee"
							@change="updateQuery"
						>
						</v-select> -->
					</td>
					<!-- <td>
						<v-select
							v-model="filter.for_company"
							class="border-all"
							placeholder="Для кого"
							:menu-props="{ offsetY: true }"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Сотрудники', value: 'true' },
								{ text: 'Жители', value: 'false' },
							]"
							@change="updateQuery"
						>
						</v-select>
					</td> -->
					<!-- <td>
						<v-select
							v-model="filter.type_id"
							class="border-all"
							solo
							dense
							flat
							hide-details
							item-text="name"
							item-value="id"
							:items="[
								{ name: 'Все', id: null },
								...catalog.notification_types,
							]"
							@change="updateQuery"
						>
						</v-select>
					</td> -->
					<td>
						<!-- <DatePickerPlain
							:date.sync="filter.created_at"
							:in-table="true"
							@change="updateQuery"
						/> -->
					</td>
					<!-- <td>
						<v-select
							v-model="filter.read"
							class="border-all"
							placeholder="Прочтение"
							:menu-props="{ offsetY: true }"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Да', value: 'true' },
								{ text: 'Нет', value: 'false' },
							]"
							@change="updateQuery"
						>
						</v-select>
					</td> -->
					<td>
						<!-- <v-select
							v-model="filter.files"
							class="border-all"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Да', value: '1' },
								{ text: 'Нет', value: '0' },
							]"
							@change="updateQuery"
						>
						</v-select> -->
					</td>
				</tr>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.created_by_user`]="{ item }">
				<td>
					{{ item.created_user | fullname }}
				</td>
			</template>
			<template v-slot:[`item.type_id`]="{ item }">
				<td>
					{{ item.type.name }}
				</td>
			</template>
			<template v-slot:[`item.name`]="{ item }">
				<td>
					<p class="ma-3 text-line-3">{{ item.name }}</p>
				</td>
			</template>
			<template v-slot:[`item.description`]="{ item }">
				<span class="desc-bg">
					<p class="ma-3 text-line-3">{{ item.description }}</p>
				</span>
			</template>
			<template v-slot:[`item.houses`]="{ item }">
				<span class="address-column" v-if="item.houses && item.houses.length > 0">
					<span class="ma-3 text-line-3">{{ truncateAddresses(item.houses) }}</span>
				</span>
				<td v-else-if="item.house">
					<span>{{ item.house.apartment_address }}</span>
				</td>
			</template>
			<template v-slot:[`item.for_whom`]="{ item }">
				<td>
					{{ item.for_company ? 'Сотрудники' : 'Жители' }}
				</td>
			</template>
			<template v-slot:[`item.created_at`]="{ item }">
				<td>
					{{ item.created_at | timestamp(true) }}
				</td>
			</template>
			<template v-slot:[`item.read`]="{ item }">
				<td>
					{{ item.is_read ? "Да" : "Нет" }}
				</td>
			</template>
			<template v-slot:[`item.files`]="{ item }">
				<td>
					<span v-if="item.files.length">
						<v-icon color="primary" style="transform: rotate(45deg);">mdi-paperclip</v-icon> ({{ item.files.length }})
					</span>
					<span v-else class="ml-2">Нет</span>
				</td>
			</template>
			<!-- <template v-slot:[`item.edit`]="{ item }">
				<td>
					<v-btn icon @click="handleEdit(item)">
						<v-icon color="primary">mdi-pencil</v-icon>
					</v-btn>
				</td>
			</template> -->
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ ЗАЯВКИ -->
		<NotificationsTableModal
			v-if="showModalCreate"
			:toEdit="toEdit"
			@close-modal="closeModalCreate"
		/>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";
import Combobox from '@/components/app/Combobox.vue';
import addressFilterMixin from "@/mixins/addressFilterMixin";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import NotificationsTableModal from "@/components/NotificationsTableModal";

export default {
	name: "NotificationsTable",
	mixins: [addressFilterMixin],
	components: {
		Combobox,
		DatePickerPlain,
		NotificationsTableModal,
	},
	data() {
		return {
			items: [],
			employee: [],
			filter: {
				user_ids: this.$route.query.user_ids || [],
				created_at: this.$route.query.created_at || "",
				created_at_first_search:
					this.$route.query.created_at_first_search || "",
				created_at_second_search:
					this.$route.query.created_at_second_search || "",
				house_ids: this.$route.query.house_ids || "",
				house_name: this.$route.query.house_name || "",
				pretty_id: this.$route.query.pretty_id || "",
				company_ids: this.$route.query.company_ids || [],
				files: this.$route.query.file_exists || "",
				message: this.$route.query.message || "",
				title: this.$route.query.title || "",
				type_id: this.$route.query.type_id || "",
				read: this.$route.query.read || "",
				for_company: this.$route.query.for_company || "",
				name: this.$route.query.name || "",
				description: this.$route.query.description || "",
			},
			filtersQuery: {
				"user_ids": "user_ids",
				"created_at": "created_at",
				"created_at_first_search": "created_at_first_search",
				"created_at_second_search": "created_at_second_search",
				"house_ids": "house_ids",
				"house_name": "house_name",
				"pretty_id": "pretty_id",
				"company_ids": "company_ids",
				"files": "file_exists",
				"page": "page",
				"name": "name",
				"description": "description",
				"message": "message",
				"title": "title",
				"type_id": "type_id",
				"read": "read",
				"for_company": "for_company",
			},
			headers: [
				{ text: "№ ID", value: "pretty_id", width: "120px", sortable: false },
				{ text: "Тема", value: "name", width: "200px", sortable: false },
				{
					text: "Сообщение",
					value: "description",
					width: "300px",
					sortable: false,
				},
				{ text: "Дом", value: "houses", width: "250px", sortable: false },
				{
					text: "От кого",
					value: "created_by_user",
					width: "250px",
					sortable: false,
				},
				// {
				// 	text: "Для кого",
				// 	value: "for_whom",
				// 	width: "250px",
				// 	sortable: false,
				// },
				// { text: "Тип", value: "type_id", width: "250px", sortable: false },
				{ text: "Дата", value: "created_at", width: "200px", sortable: false },
				// { text: "Прочтение", value: "read", width: "150px", sortable: false },
				{ text: "Вложения", value: "files", width: "150px", sortable: false },
			],
			headersDispatcher: [
				{ text: "№ ID", value: "pretty_id", width: "120px", sortable: false },
				{ text: "УК", value: "company.name", width: "250px", sortable: false },
				{ text: "Тема", value: "name", width: "200px", sortable: false },
				{
					text: "Сообщение",
					value: "description",
					width: "400px",
					sortable: false,
				},
				{ text: "Дома", value: "houses", width: "350px", sortable: false },
				{
					text: "От кого",
					value: "created_by_user",
					width: "250px",
					sortable: false,
				},
				// { text: "Тип", value: "type_id", width: "250px", sortable: false },
				{ text: "Дата", value: "created_at", width: "200px", sortable: false },
				// { text: "Прочтение", value: "read", width: "150px", sortable: false },
				{ text: "Вложения", value: "files", width: "150px", sortable: false },
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			isExportingExcel: false,
			loading: true,
			firstFetch: false,
			showModalCreate: false,
			toEdit: null,
			selectAllUserId: false,
			selectAllHouseId: false,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			base_role: "auth/GET_USER_ROLE",
			role: "auth/GET_USER_ROLE_VUEX",
			kskId: "auth/GET_KSK_ID",
			kskListConfirm: "catalog/GET_KSK_LIST_CONFIRM",
			userHouses: "auth/GET_HOUSES_IDS",
		}),
		// notificationsTypes() {
		// 	return this.catalog.notification_types.filter(e => e.sys_name !== 'inner')
		// }
	},
	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
					const query = { ...this.$route.query, page: this.options.page };
					this.$router.replace({ query });
					this.loadData(query);
				}
			},
			deep: true,
		},
	},
	methods: {
		truncateText(text, maxLength) {
			if (text.length > maxLength) {
					return text.substring(0, maxLength) + '...';
			}
			return text;
    },
		truncateAddresses(addresses) {
			const combinedAddresses = addresses.map(house => house.apartment_address).join(", ");
			return combinedAddresses;
		},
		updateFilter(filterName, value) {
      this.filter[filterName] = value;
			this.updateQuery()
    },
		isSelected(item) {
			return this.filter.user_ids.includes(item.id);
		},
		currentHouses(item) {
			if(item.length > 1) {
				const result = this.houses.find(({ id }) => id === item);
				return result?.apartment_address
			}
		},
		selectAll() {
			if (this.selectAllUserId) {
        this.filter.user_ids = [];
      } else {
				this.filter.user_ids = this.employee.map((item) => item.id);
				this.updateQuery()
      }
      this.selectAllUserId = !this.selectAllUserId;
    },
		selectAllHouses() {
			if (this.selectAllHouseId) {
        this.filter.house_ids = [];
      } else {
				this.filter.house_ids = this.houses.map((item) => item.id);
				this.updateQuery()
      }
      this.selectAllHouseId = !this.selectAllHouseId;
    },
		handleRowClick(item) {
			// if (this.role === "dispatcher") return;
			if (window.getSelection().toString()) {
				return;
			}
			this.toEdit = item;
			this.toEdit.houses_ids = [item.house_ids];
			this.toEdit.uploaded_files = [];
			this.showModalCreate = true;
		},
		handleEdit(item) {
			this.toEdit = item;
			this.toEdit.houses_ids = [item.house_ids];
			this.toEdit.uploaded_files = [];
			this.showModalCreate = true;
		},

		closeModalCreate() {
			this.showModalCreate = false;
			this.toEdit = null;
			const query = { ...this.$route.query, page: this.options.page };
			this.loadData(query);
		},
		// ? Начало методов таблицы
		async exportExcel() {
			this.isExportingExcel = true;
			try {
				const query = this.updateQuery(true);
				const res = await this.$api.ksk.export_notifications_list(query);
				const link = document.createElement("a");
				link.href = res;
				link.download = `Уведомления ${timestampToDdmmyyyy()}.xlsx`;
				link.click();
			} catch (error) {
				console.error(error);
			} finally {
				this.isExportingExcel = false;
			}
		},

		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.$router.replace({ query: null });
			this.firstFetch	= false
			await this.loadData({});
		},

		async loadData(params) {
			console.log('params', params)
			this.loading = true;
			if(this.base_role == 'dispatcher') {
				if (!params.company_ids || params.company_ids.length === 0) {
					params.company_ids = this.kskListConfirm.map(item => item.id);
				}
			} else {
				params.company_ids = [this.kskId]
				params.selected_ksk_id = this.kskId
			}
			if(!params.house_ids) {
				if(this.base_role !== 'ksk') {
					const houseDataArray = this.userHouses.map(item => item.house);
					params.houses_ids = houseDataArray.map(item => item.id)
				}
			} else {
				params.houses_ids = params.house_ids
				delete params['house_ids'];
			}
			return this.$api.ksk
				.get_notifications(params)
				.then(res => {
					this.options.page = res.currentPage;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
					this.firstFetch = true;
				});
		},

		updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			this.firstFetch	= false
			this.loadData(query);
		},
		// ? Конец методов таблицы
	},
	async created() {
		this.$store.dispatch("catalog/loadKskListConfirm", this.base_role);
	},
	async mounted() {
		try {
			this.$store.dispatch("ksk/load_houses");
			// await this.$store.employee.load_employee();
			const roles = this.catalog.roles.filter(role => {
				return role.name_system === 'ksk' || role.name_system === 'ksk_dispatcher' || role.name_system === 'ksk_manager';
			});
			let params = {
				role_ids: [],
				company_ids: [this.kskId],
				short: true,
			}
			roles.forEach(role => {
				params.role_ids.push(role.id);
			});
			this.employee = await this.$api.ksk.load_employee(params);
			const query = { ...this.$route.query, page: this.options.page };
			await this.loadData(query);
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.notifications-table {
	height: 100vh;
}
.desc-bg {
	background-color: rgba(146, 227, 169, 0.2);
	display: flex;
	min-height: 100%;
	align-items: center;
}
.address-column {
	display: flex;
	min-height: 100%;
	align-items: center;
}
.text-line-3 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
}
</style>

<template>
	<ul class="files-list d-flex pa-0 pb-3 scrollbar">
		<li
			v-for="item of itemsCopy"
			:key="item.id"
			class="files-list__item relative d-flex flex-column mr-3"
		>
			<div class="files-list__img" @click="handleClickImg(item)">
				<v-img
					:src="getSrc(item)"
					max-width="50"
					min-height="50"
					max-height="50"
				>
				</v-img>
			</div>
			<div class="text-caption mt-4">{{ item.original_name }}</div>

			<v-btn
				v-if="clearable"
				depressed
				x-small
				absolute
				right
				icon
				class="mybtn mybtn-close"
				color="primary"
				@click="handleClickClose(item)"
			>
				<v-icon small>mdi-delete</v-icon>
			</v-btn>

			<v-btn
				class="mybtn mybtn-download"
				icon
				x-small
				absolute
				right
				top
				@click="handleClickDownload(item)"
			>
				<v-icon>mdi-download</v-icon>
			</v-btn>
		</li>

		<ModalConfirm
			v-if="showConfirmModal"
			title="Подтверждение удаления"
			@close-modal="handleConfirm"
		>
			<template #body>
				<span>
					Удалить файл <b>{{ toDelete.original_name }}</b
					>?
				</span>
			</template>
		</ModalConfirm>

		<v-overlay
			v-if="showImage"
			class="relative"
			opacity="0.5"
			:overlay="false"
			@click="showImage = null"
		>
			<v-btn class="mybtn-overlay" icon @click="showImage = null">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-img :src="getSrc(showImage)"> </v-img>
		</v-overlay>
	</ul>
</template>

<script>
import ModalConfirm from "@/components/app/ModalConfirm";

export default {
	name: "FilesList",

	components: { ModalConfirm },

	props: {
		clearable: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default: () => [],
		},
		deleted: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			itemsCopy: [],
			filesToDelete: [],
			showConfirmModal: false,
			showImage: null,
			toDelete: null,
		};
	},

	methods: {
		isImage({ extension }) {
			return ["jpg", "jpeg", "png"].includes(extension);
		},

		getSrc({ extension, path }) {
			if (this.isImage({ extension })) return this.loadServerImage(path);
			return require(`@/assets/${extension}_icon.png`);
		},

		handleClickClose(e) {
			this.toDelete = e;
			this.showConfirmModal = true;
		},

		async handleClickDownload({ extension, path, original_name }) {
			// if (this.isImage({ extension })) return;
			const url = this.loadServerImage(path);
			const link = document.createElement("a");
			link.href = url;
			link.target = "_blank";
			link.download = original_name;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},

		handleClickImg(e) {
			return;
			if (!this.isImage(e)) return;
			this.showImage = e;
		},

		handleConfirm(isConfirmed) {
			this.showConfirmModal = false;

			if (!isConfirmed) return;

			const { id } = this.toDelete;

			this.filesToDelete.push(id);
			this.itemsCopy = this.itemsCopy.filter(e => e.id !== id);

			this.$emit("update:deleted", this.filesToDelete);
		},
	},

	created() {
		this.itemsCopy = [...this.items];
	},
};
</script>

<style lang="scss" scoped>
$width: 120px;

.files-list {
	overflow-x: scroll;
	overflow-y: hidden;
	&__item {
		width: $width;
		min-width: $width;
		.v-image {
			cursor: pointer;
		}
		&:hover {
			.mybtn-download {
				display: block;
			}
		}
	}
}
.mybtn {
	margin-right: -15px;
	&-close {
		z-index: 1;
	}
	&-download {
		display: none;
		margin-top: 5px;
	}
	&-overlay {
		position: fixed;
		top: 15px;
		right: 15px;
	}
}
</style>

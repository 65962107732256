<template>
	<v-col cols="12" class="notifications-table-modal-personal">
		<!-- Ряды выбранных адресов -->
		<v-row>
			<!-- Ряд определенного адреса -->
			<v-col
				v-for="(address, index) in addresses"
				:key="index"
				cols="12"
				class="px-0"
			>
				<v-row>
					<!-- Поле выбора адреса -->
					<v-col cols="3" class="pb-0">
						<v-autocomplete
							v-model="address.apartment_address"
							placeholder="Укажите адрес"
							persistent-placeholder
							class="border-all"
							solo
							dense
							hide-details
							flat
							required
							item-text="apartment_address"
							item-value="_id"
							:items="houses"
							:filter="addressFilter"
						>
						</v-autocomplete>
					</v-col>
					<!-- Поле выбора типа помещения -->
					<v-col cols="3" class="px-0 pb-0">
						<v-select
							v-model="address.premise_id"
							placeholder="Тип помещения"
							class="border-all"
							solo
							dense
							hide-details
							flat
							required
							item-value="sys_name"
							item-text="name"
							:items="computedPremises"
						>
						</v-select>
					</v-col>
					<!-- Ввод номеров помещений -->
					<v-col cols="6" class="pb-0">
						<v-combobox
							v-model="address.rooms"
							class="text-caption border-all"
							chips
							small-chips
							multiple
							solo
							dense
							hide-details
							flat
							:allow-overflow="false"
							label="Номера помещений"
						>
							<template v-slot:selection="{ attrs, item, select, selected }">
								<v-chip
									v-bind="attrs"
									close
									small
									:input-value="selected"
									@click="select"
									@click:close="removeRoom(item, address.rooms)"
								>
									{{ item }}
								</v-chip>
							</template>
						</v-combobox>
					</v-col>
				</v-row>
			</v-col>
			<!-- Кнопка добавления адреса -->
			<v-col class="px-0 pb-0">
				<v-btn color="primary" text small @click="addNewAddress">
					Добавить
				</v-btn>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import { mapGetters } from "vuex";
import addressFilterMixin from "@/mixins/addressFilterMixin";

export default {
	name: "NotificationsTableModalPersonal",
	mixins: [addressFilterMixin],
	data() {
		return {
			addresses: [],
			obj: {
				apartment_address: null,
				premise_id: "apartment",
				rooms: [],
			},
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
		computedPremises() {
			// * Возвращает только [Квартира, НП, Паркоместо]
			return [...this.catalog.premises.slice(0, 3)];
		},
	},
	methods: {
		// * Добавление нового адреса с использованием Deep Copy
		addNewAddress() {
			const objCopy = JSON.parse(JSON.stringify(this.obj));
			this.addresses.push(objCopy);
		},
		removeRoom(item, arr) {
			arr.splice(arr.indexOf(item), 1);
			arr = [...arr];
		},
	},
	mounted() {
		// Изначально добавлен один адрес
		this.addNewAddress();
	},
};
</script>

<style></style>

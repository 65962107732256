<template>
	<v-dialog v-model="show" max-width="900" z-index="1000">
		<v-carousel v-model="index" height="auto" style="width: 100%;">
			<v-carousel-item
				v-for="(img, i) in images"
				:key="i"
				reverse-transition="fade-transition"
				transition="fade-transition"
				style="display: flex; justify-content: center; align-items: center;"
			>
				<div v-if="index === i" class="p-relative">
					<img v-if="img.image" :src="img.image" style="max-height: 700px; max-width: 100%; height: auto; width: auto; margin: 0 auto; display: block;">
					<v-btn @click="downloadImage(img)" :loading="loading" text rounded class="download-btn text-none white--text">
						<v-icon>mdi-download</v-icon>&nbsp;
						Скачать
					</v-btn>
				</div>
			</v-carousel-item>
		</v-carousel>
	</v-dialog>
</template>

<script>
export default {
	name: "ImagesCarousel",
	props: {
		images: {
			required: true,
			default: [],
		},
		initialIndex: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			show: true,
			loading: false,
			index: this.initialIndex,
		};
	},
	watch: {
		show() {
			setTimeout(() => this.$emit("close-modal"), 100);
		},
	},
	methods: {
		async downloadImage(img) {
			try {
				this.loading = true
				const response = await this.$api.ksk.download_file(img.id)
				console.log('res', response)
				const blob = new Blob([response], { type: 'application/octet-stream' });
				const blobUrl = URL.createObjectURL(blob);
				const link = document.createElement('a');
				console.log('blobUrl', blobUrl)
				link.href = blobUrl;
				console.log('link', link)
				link.download = img.name;
				link.click();
				URL.revokeObjectURL(blobUrl);
				this.loading = false
			} catch (error) {
				console.log('err', error)
				this.$root.snackbar.show({ color: "red", message: "Произошла ошибка. Повторите позже" });
				this.loading = false
			}
		}
	},
};
</script>

<style scoped>
.p-relative {
	position: relative;
}

.download-btn {
	position: absolute;
	right: 10px;
	top: 15px;
	background-color: #676767;
	border: 1px solid #fff;
}
</style>
